import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { gsap, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
// import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import prizeImage1_en from '../../images/grandprize.jpg';
// import prizeImage1_fr from '../../images/prizes_fr.png';
import prizeImage2_en from '../../images/oreobackpack3.png';
// import prizeImage2_fr from '../../images/cooler_fr.png';
import zeroG_en from '../../images/zerog.png';

class PrizeDetails extends Component{

    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        gsap.from('.page-heading, .prize-details__text-section', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        });
    }

    render(){
        // const langPreferance = this.props.lang;
        const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper prize-details no-short-rules">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="page-heading">
                                        <h1>{translate('prizeDetails')}</h1>
                                    </div>
                                    <div className="row prize-details__text-section grand-prize">
                                        <div className="cookie bobber"></div>
                                        <div className="col-md-6">
                                            <h2>{translate('prizeDetails-heading1')}</h2>
                                            <p>{translate('prizeDetails-prod1', {br : <br />, b: chunks => <b>{chunks}</b>, a: chunks => <NavLink to="/rules">{chunks}</NavLink>})}</p> 
                                        </div>
                                        <div className="col-md-6 img">
                                            <img src={prizeImage1_en} alt="The Grand Prize" />
                                            <img src={zeroG_en} alt="Zero G" />
                                            
                                        </div>
                                    </div>
                                    <div className="row prize-details__text-section">
                                        <div className="cookie bobber reverse"></div>
                                        <div className="col-md-6 img">
                                            <img src={prizeImage2_en } 
                                                alt="Instant Prizes" 
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6">
                                            <h2>{translate('prizeDetails-heading2')}</h2>
                                            <p>{translate('prizeDetails-body2', {br : <br />, b: chunks => <b>{chunks}</b>})}</p>
                                        </div>
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default PrizeDetails;