import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// import { TextField } from '@material-ui/core';
// import ReactGA from 'react-ga';

//translate
import { translate } from '../../i18n/translate';

//images
import packshot from '../../images/spacedunk-packshot.png';


class TooMany extends Component{

    componentDidMount(){
        // console.log('you won - cdm');
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
    
        return(
            <Fragment>

                <div id="main" className="main-wrapper bg-both-dt no-short-rules">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                <div className="you-win">
                                            <h1>{translate('tmi.heading', {br: <br />})}</h1>
                                            <p>{translate('tmi.body',{br: <br />, a: chunks => <NavLink to="/rules">{chunks}</NavLink>})}</p>
                                            <div className="text-center cookie-wrap packshots">
                                                {/* <div className="floating-cookie1 bobber"></div> */}
                                                <img src={packshot} ref={el => {this.prize1 = el}} alt="Oreo Space Dunk package" className="img-fluid" />
                                                <div className="floating-cookie2 bobber slow"></div>
                                                <div className="floating-cookie3 bobber reverse"></div>
                                            </div>
                                            <NavLink className="enter-btn" to="/products">{translate('winningPage.trybutton',{b:c=><b>{c}</b>})}</NavLink>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default TooMany;