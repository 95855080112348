import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import liftoff_mb_en from '../../images/liftoff-with-oreo-on-zerog-ver-en.png';
import liftoff_dt_en from '../../images/liftoff-with-oreo-on-zerog-hor-en.png';
import liftoff_fr from '../../images/liftoff-with-oreo-on-zerog-fr.png';
// import zerog from '../../images/zerog.png';
import win_en from '../../images/win.png';
import win_fr from '../../images/win-fr.png';

class ContestClosed extends Component {

    state = {
        isPlaying: true,
    }

    handleVideoClick = () => {
        // console.log('click',this.state.isPlaying);
        this.setState({isPlaying:!this.state.isPlaying});
        if (this.state.isPlaying){
            this.videl.pause();
        } else {
            this.videl.play();
        }
        
    }

    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        gsap.from('.prize-money, .liftoff-title, .video-frame, .heading-text, .comingsoon-body', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        });
    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div id="main" className="main-wrapper">
                    <div className="container home coming-soon">
                        <div className="row">
                            <div className="col home-text-container text-center"> {/*before it was col-md-6*/}

                                <h1 className="prize-money">{translate('comingsoon')}</h1>

                                <img src={this.props.lang==='fr-CA'?liftoff_fr:liftoff_dt_en} className="img-fluid d-none d-sm-block mx-auto liftoff-title" alt="Lift Off with Oreo" />
                                <img src={this.props.lang==='fr-CA'?liftoff_fr:liftoff_mb_en} className="img-fluid d-block d-sm-none mx-auto liftoff-title" alt="Lift Off with Oreo" />

                                <div className="video-frame">
                                    <video autoPlay={this.state.isPlaying} muted loop playsInline onClick={this.handleVideoClick} ref={el=>{this.videl = el}}>
                                        <source src="/video/your-first-step-into-space-edit3.mp4" />
                                    </video>
                                </div>

                                <div className="heading-text">
                                    <h1 className="prize-money">
                                        <span>{translate('homepage.prize1')}</span>
                                        <img src={this.props.lang==='fr-CA'?win_fr:win_en} alt="WIN*" />
                                        <span>{translate('homepage.prize')}</span>
                                    </h1>
                                </div>
                                <div className="">
                                    
                                    <p className="comingsoon-body">{translate('comingsoon.body',{br: <br/>,b:c=><b>{c}</b>})}</p>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;