import React, { Component, Fragment } from 'react';
import { TweenMax, Power3 } from 'gsap';
// import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import liftoff_mb_en from '../../images/liftoff-with-oreo-on-zerog-ver-en.png';
import liftoff_dt_en from '../../images/liftoff-with-oreo-on-zerog-hor-en.png';
import liftoff_fr from '../../images/liftoff-with-oreo-on-zerog-fr.png';
// import zerog from '../../images/zerog.png';
// import win_en from '../../images/win.png';
// import win_fr from '../../images/win-fr.png';

class ContestClosed extends Component {

    state = {
        isHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }


    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        const { headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        TweenMax.staggerFrom([headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div id="main" className="main-wrapper no-short-rules">
                    <div className="container home">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 home-text-container text-center closed-pad"> {/*before it was col-md-6*/}

                                <h1 className="prize-money" ref={el => {this.headingTwo = el}}>{translate('contestClosedHead')}</h1>

                                <img src={this.props.lang==='fr-CA'?liftoff_fr:liftoff_dt_en} className="img-fluid d-none d-sm-block mx-auto" alt="Lift Off with Oreo" />
                                <img src={this.props.lang==='fr-CA'?liftoff_fr:liftoff_mb_en} className="img-fluid d-block d-sm-none mx-auto" alt="Lift Off with Oreo" />

                                <p className="closed-body">{translate('contestClosedText', {br: <br/>})}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;