import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Décollez ave OREO',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        // 'menu.item.winner' : 'ANCIENS GAGNANTS',
        'menu.item.1': 'PARTICIPER AU CONCOURS',
        'menu.item.2': 'DÉTAILS DES PRIX',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RÈGLEMENT',
        'menu.item.5': 'MISSION SPATIALE OREO',
        
        'contact-email': 'customercare@oreodecolle.com',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Confidentialité des données',
        'footer.link3' : 'Responsable de la protection de la vie privée, Québec',
        'footer.link4' : 'Conditions d’Utilisation',
        'footer.link5' : 'Politique d’accessibilité',
        'footer.sponsor': 'Commanditaire : Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9{br}{br}Groupe ©Mondelez International.{br}{br}Toutes les marques de commerce appartiennent à leurs propriétaires respectifs. Zero-G n’est pas responsable de cette promotion et n’en est pas le commanditaire.',

        //homapge
        'homepage.gameon' : '',
        'homepage.unlock': '',
        'homepage.prize1' : 'VOUS POURRIEZ',
        'homepage.prize' : 'UNE EXPÉRIENCE ZÉRO GRAVITÉ',
        // 'homepage.pluscash': 'Avec des',
        // 'homepage.pluscash2': 'sacs à collation en prix* instantanés',
        // 'homepage.pluscash3': '(VDA de 68 $)',
        // 'homepage.prepluscash': '50 000 $ EN ÉQUIPEMENT AVEC DES SACS À COLLATION EN PRIX INSTANTANÉS* (VDA 68$)',
        // 'homepage.preprize': 'CONCOURS ZONE DE COLLATION À VENIR!',
        // 'homepage.cta' : 'FAITES DÉFILER POUR SAVOIR COMMENT PARTICIPER',
        // 'homepage.howtoenter' : 'COMMENT PARTICIPER',
        // 'homepage.step1head' : 'ÉTAPE 1',
        // 'homepage.step1' : 'Saisissez trois (3) CUP participants au choix',
        // 'homepage.step2head' : 'ÉTAPE 2',
        // 'homepage.step2' : 'Recherchez le CUP du produit sur l’emballage de votre produit Mondelez participant.',
        // 'homepage.step3head' : 'ÉTAPE 3',
        // 'homepage.step3' : "Recherchez le code UPC à 11 chiffres** du produit  figurant sur l'emballage de votre produit Mondelez participant. <small>**Omettez tout chiffre à gauche du code.</small>",
        // 'homepage.offerhead': 'L’ÉTÉ OFFRE DES S’MORES À EXPLORER!',
        // 'homepage.prod1': 'S’MORES AUX RITZ, BEURRE D’ARACHIDE ET CONFITURE',
        // 'homepage.prod1url': 'https://www.snackworks.ca/en/recipe/pbj-ritz-smores-206344.aspx',
        // 'homepage.prod2': 'S’MORES AU CARAMEL, PACANES ET CHIPS AHOY!',
        // 'homepage.prod2url': 'https://www.snackworks.ca/en/recipe/caramel-pecan-chips-ahoy-smores-206343.aspx',
        // 'homepage.prod3': 'BROWNIES S’MORES CADBURY',
        // 'homepage.prod3url': 'https://www.snackworks.ca/en/recipe/cadbury-smores-crispy-treats-133481.aspx',
        // 'homepage.offercta': 'ALLER À LA RECETTE',

        // 'contestClosedHead1' : 'Zone Des Collations',
        'contestClosedHead' : 'LE CONCOURS EST TERMINÉ',
        'contestClosedText' : "Merci de votre visite!{br}{br}Notre concours est maintenant terminé, mais si vous avez gagné le Grand prix, vous recevrez un courriel après le 5 juin 2024. Bonne chance!",
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterCta' : 'PARTICIPEZ MAINTENANT',
        'enterNow' : 'PARTICIPEZ MAINTENANT',

        'legal' : '*AUCUN ACHAT REQUIS. Ouvert aux résidents du Canada (majeurs). Le Concours se termine le 31 mai 2024 (23:59:59 HE). L’inscription en ligne et le règlement complet se trouvent au : www.OREODÉCOLLE.ca. Les prix à gagner sont : un (1) Grand prix consistant en une expérience de vol Zéro gravité (VDA 30 000 $ CA); 30 prix instantanés avec thématique de l’espace à l’effigie de la marque OREO (VDA 250 $ CA). Les chances de gagner dépendent du nombre de participations admissibles. Question d’habileté requise.',
        'legal.link' : 'www.oreodecolle.ca',

        'comingsoon' : 'DÉCOLLAGE À VENIR',
        'comingsoon.body' : 'Revenez le <b>15 février pour participer!</b>',

        //enter contest page
        // 'step1': 'Étape 2',
        // 'enterPin': 'SAISIR LES CUP',
        // 'enterKeyDescription': "Saisissez les 11 chiffres** du code CUP du produit  figurant sur l'emballage de votre produit Mondelez participant.",
        // 'enterKeyLink': 'l’emballage de votre produit Mondelez participant.',
        // 'enterKeySmall': '**Omettez tout chiffre à gauche du code.',
        // 'popupBodytext' : 'Recherchez le code UPC au dos de votre achat d’un ',
        // 'popupBodylink' : 'produit participant.',
        // 'pin': 'CUP',
        // 'nextStep': 'SUIVANT',

        // 'step2': 'Étape 1',
        'enterInfo': 'DÉVERROUILLEZ LES DÉTAILS DE LA MISSION',
        'enterInfoDescription': 'Inscrivez-vous pour une chance de GAGNER* cette aventure avec <b>Zero-G</b> qui va changer votre vie, en plus de prix instantanés! Vous pouvez participer tous les jours!',
        'firstName': 'Prénom',
        'lastName': 'Nom de famille',
        'emailAddress': 'Adresse courriel',
        'phone': 'Téléphone',
        'infoForm.age': 'Je confirme avoir atteint l’âge de la majorité dans ma province ou mon territoire de résidence†',
        'infoForm.rules' : 'J’ai lu le ',
            'infoForm.rules.link1' : 'Règlement du concours',
            'infoForm.rules2' : ' et la ',
            'infoForm.rules.link2' : 'Politique en matière de protection des renseignements personnels de Mondelez Canada inc.',
            'infoForm.rules3' : ' et j’accepte de m’y conformer†',
        'infoForm.optin' : 'Je confirme mon consentement à ce que Mondelez Canada Inc. conserve, partage et utilise les renseignements personnels que j’ai fournis dans le but d’administrer le Concours (y compris m’informer si je suis sélectionné(e) comme gagnant(e) admissible d’un prix) conformément au Règlement du concours et à la Politique en matière de protection des renseignements personnels de Mondelez†',
        'requiredFields' : '†Champs obligatoires',
        'submit': 'Soumettre',

        'dunk.header' : 'COUREZ LA CHANCE DE GAGNER* UN PRIX AVEC DES BISCUITS',
        'dunk.body' : 'Faites défiler afin de choisir votre biscuit <b>OREO</b>. Cliquez et maintenez votre biscuit OREO, puis faites-le glisser pour le faire tremper dans le lait. Vous pourrez alors voir si vous avez déverrouillé un prix instantané.',

        'tmi.heading' : 'YOUPI!',
        'tmi.body' : 'SIl semble que vous ayez déjà eu votre participation au concours et que vous avez déjà joué aujourd’hui. Revenez tous les jours jusqu’au 31 mai 2024 pour plus de chances de GAGNER*!{br}{br}*Voir le <a>règlement du concours</a> pour plus de détails.',

        //winning page
        'congratulation' : 'VOUS Y ÊTES PRESQUE!',
        'winningPage.heading' : 'VOUS ÊTES UN GAGNANT POTENTIEL!',
        'winningPage.body' : 'Avant d’être déclaré un gagnant officiel, vous devez répondre correctement, sans aide, à la question d’habileté mathématique ci-dessous.',
        'winningPage.question' : '(5X5) – 13, puis ajoutez 3 =',
        'winningPage.answer' : "Votre Réponse",
        'winningPage.cta' : 'SOUMETTRE',
        'winningPage.field1': 'Adresse',
        'winningPage.field2': 'Appartement/Suite',
        'winningPage.field3': 'Ville',
        'winningPage.field4': 'Province/Territoire',
        'winningPage.field5': 'Code postal',

        'winningPage.heading1' : 'FÉLICITATIONS!',
        'winningPage.heading2' : 'VOUS ÊTES UN GAGNANT INSTANTANÉ',
        'winningPage.body1' : 'Vous avez été sélectionné pour GAGNER* un sac à doc personnalisé avec thématique de l’espace OREO!{br}{br}<small>*Sujet à une vérification</small>',
        'winningPage.body2' : 'Afin que nous puissions vous donner votre prix, veuillez remplir les champs suivants :',
        // 'winningPage.legal' : 'Veuillez prévoir un délai de 4 semaines pour la livraison. serviceclient@zonedecollations.ca',

        // 'winningPage.heading3' : 'DÉSOLÉ, MAUVAISE RÉPONSE.',
        // 'winningPage.body3' : 'Veuillez réessayer avec un autre code UPC.',

        'winningPage.heading4' : 'COMMENT PUIS-JE RÉCLAMER MON PRIX?',
        'winningPage.body4': 'Vous allez recevoir un courriel de la part de {emaillink}. Vous y retrouverez les informations de votre prix dans un délai de 72 heures.',
        'winningPage.trybutton': 'GOÛTEZ L’ESPACE',

        'address1': 'Adresse',
        'address2': 'Appartement/Suite',
        'city': 'Ville',
        'prov': 'Province/Territoire',
        'postal': 'Code postal',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Télécharger',

        //losing page
        'tryAgain' : 'ZUT!',
        'tryAgain.body' : 'Vous n’avez pas gagné* un prix instantané cette fois-ci, mais vous avez tout de même obtenu une participation au concours pour gagner le grand prix de Zero-G!{br}{br}Revenez tous les jours afin d’avoir plus de chances de GAGNER*.',
        // 'tryAgain.btn' : 'ESSAYEZ DE NOUVEAU', 
        'tryAgain.heading2' : 'PARTAGEZ',
        'tryAgain.body2' : '',
        'tryAgain.body3' : 'Voulez-vous partager sur Instagram?  Cliquez sur le bouton Télécharger ci-dessous; vous allez recevoir une image que vous pourrez télécharger sur votre compte.',

        //Prize Details
        'prizeDetails' : 'DÉTAILS DES PRIX',

        'prizeDetails-heading1' : 'LE GRAND PRIX',
        'prizeDetails-prod1' : "Vous pourriez voler dans un jet Zero-G pour un tour qui vous permettra de flotter, de faire des pirouettes et de planer. Ressentez l'apesanteur comme un astronaute dans l'espace!{br}{br}Commencez l'aventure au NASA’s Kennedy Space Center, où vous recevrez une formation de vol, une combinaison de vol <b>OREO</b> et bien plus!{br}{br}*Consultez le <a>règlement</a> du concours pour plus de détails.",
        'prizeDetails-heading2' : 'PRIX INSTANTANÉS',
        'prizeDetails-body2' : "Se lancer vers de nouveaux horizons signifie que vous aurez besoin de l’équipement le plus moderne! Ce sac à dos personnalisé avec thématique de l'espace <b>OREO</b> est parfait pour toutes vos aventures.",

        // Participating Products
        'products' : 'GOÛTEZ L’ESPACE!',
        
        'products.body1' : 'Est-ce que vous vous demandez ce que goûte la crème cosmique? C’est le même goût délicieux des guimauves.{br}{br}Essayez les nouveaux biscuits <b>OREO</b> Mission spatiale à série limitée!{br}{br}Les biscuits contiennent une crème à saveur de guimauve avec des bonbons pétillants « poussière d’étoiles » et ils sont offerts avec 5 motifs de biscuits galactiques.',
        'products.link1' : 'ACHETEZ MAINTENANT',
        'products.body2' : 'Même si ce biscuit <b>OREO</b> peut se trouver sur la Terre, inscrivez-vous afin de courir la chance de GAGNER* une expérience Zéro Gravité; vous ne regretterez pas!',
        'products.link2' : '*Consultez le <a>règlement du concours</a> pour plus de détails.',

        //faq page
        'faq-heading-text1': 'Le ',
            'faq-heading-link': 'Règlement officiel',
        'faq-heading-text2': ' contient tous les détails dont vous avez besoin, mais il se peut que la réponse à votre question se trouve déjà ici!',

        'q1': 'Q. Quelle est la période de cette promotion?',
        'a1': 'R. Le Concours commence le 15 février (00:00:00 HE) et se termine le 31 mai 2024 (23:59:59 HE).',
        
        'q2': 'Q. Quels sont les prix de cette promotion?',
        'a2': 'R. Il y aura un (1) Grand prix consistant en une expérience de vol Zéro gravité OREO pour 2 personnes à la NASA Kennedy Space Center, en Floride; un séjour de 5 nuits pour 2 personnes à Orlando comprenant le vol, l’hôtel et 2 000 $ CA d’argent de poche. Le voyage du Grand prix doit être effectué avant le 30 novembre 2024. (VAD de 30 000 $ CA).{br}{br}Il y aura aussi trente (30) prix instantanés à gagner consistant en un sac à dos OREO au thème de l’espace (VDA 250 $ CA chacun).',
        
        'q3': 'Q. Combien de fois puis-je participer?',
        'a3': "R. Il y a une limite d’une (1) participation par personne, par jour.",
        
        'q4': 'Q. Combien de prix pourrais-je gagner?',
        'a4': "R. Il y a une limite d’un (1) Grand prix par personne, par foyer. Et une limite d’un (1) PRIX INSTANTANNÉ par personne, par foyer, lors de la promotion.",

        'q5': "Q. J'ai des codes CUP valides, mais je reçois un message d’erreur",
        'a5': 'A. Veuillez saisir le(s) code(s) sans tirets ni espaces et consulter notre liste de ',
        'a5a': 'produits participants',
        'a5b': ' pour vous assurer que votre achat est admissible. Si le code ne fonctionne toujours pas, nous sommes là pour vous aider! Veuillez nous contacter à ',

        'q6': "Q. J’ai reçu un message indiquant que j’ai déjà participé aujourd’hui, mais ce n’est pas vrai. Que devrais-je faire?",
        'a6': 'R. Assurez-vous de vous rendre directement sur la page www.OREODÉCOLLE.ca plutôt que sur une page sauvegardée dans votre navigateur.  Assurez-vous aussi de supprimer vos témoins et votre mémoire cache dans votre navigateur.',

        'q7': "Q. J’ai des problèmes techniques. Que devrais-je faire? ",
        'a7': "R. Assurez-vous que vous disposez d’une version à jour de Chrome, Internet Explorer, Firefox ou Safari. Vérifiez dans les préférences de votre navigateur que les témoins JavaScript sont activés. Dans ce cas, il est pourrait être utile de supprimer la mémoire cache et les témoins.",

        'q8': "Q. Comment saurai-je si je suis un gagnant potentiel d’un PRIX INSTANTANÉ?",
        'a8': "A. Vous serez informé à l’écran si vous avez été déclaré gagnant potentiel d’un prix instantané. Chaque gagnant potentiel devra répondre correctement à une question d’habileté affichée à l’écran pour être déclaré officiellement gagnant.",

        'q9': "Q. J’ai mis à jour mon adresse courriel il n’y a pas longtemps. Puis-je modifier mes informations d’inscription à cette promotion?",
        'a9': 'R. Nous nous excusons pour cet inconvénient, mais en raison du nombre d’inscriptions que nous recevons, nous ne sommes pas en mesure de modifier les informations relatives à l’inscription.{br}{br}Si vous participez au tirage au sort du grand prix pendant la période promotionnelle et que vos coordonnées changent, contactez-nous à l’adresse suivante : ',
        'a9-c': ' et fournissez vos coordonnées actualisées.',
        'a9-2': 'Veuillez remarquer : si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous pourrons répondre à votre demande.',

        'a9-3': "Vous avez toujours une question? Veuillez nous contacter à l’adresse : ",
        'a9-3b': 'customercare@oreoliftoff.com',
        'a9-3c': "Veuillez remarquer : le délai pour obtenir une réponse peut être de trois (3) jours ouvrables.",

        // 'q10': 'Q. J’ai des difficultés à faire fonctionner mon PC Game Pass ? Que devrais-je faire ?',
        // 'a10': 'A. Pour plus de détails sur la façon d’échanger un code, visitez ',
        // 'a10-link': 'https://support.xbox.com/fr-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        // 'q11': 'Q. J’ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations d’inscription pour cette promotion ?',
        // 'a11': 'A. Nous vous prions de bien vouloir nous excuser pour tout inconvénient, mais en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations d’inscription. Si vous êtes inscrit au tirage au sort du Grand Prix pendant la période de promotion et que vos coordonnées changent, contactez ',
        // 'a11-2' : ' et fournissez vos informations mises à jour. {br}{br}Veuillez noter : Si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous serons en mesure de satisfaire votre demande. {br}{br}Vous avez encore une question ? Veuillez nous contacter à ',
        // 'a11-3' : ' Veuillez noter que la réponse peut prendre jusqu’à trois (3) jours ouvrables.',

        //Rules page
        'rules-page-heading': 'RÈGLEMENT OFFICIEL',
    }
}