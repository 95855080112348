import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { gsap, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
// import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import products from '../../images/spacedunk-packshot.png';
import cookie1 from '../../images/cookie1.png';
import cookie2 from '../../images/cookie2.png';
import cookie3 from '../../images/cookie3.png';
import cookie4 from '../../images/cookie4.png';
import cookie5 from '../../images/cookie5.png';

class Products extends Component{

    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        gsap.from('.page-heading, .page-body > *', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        });
    }

    render(){
        const contestState = getContestState();
        const langPreferance = this.props.lang;

        return(
            <Fragment>
                <div id="main" className="main-wrapper prize-details no-short-rules">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="page-heading">
                                        <h1 className="mb-0">{translate('products')}</h1>
                                    </div>
                                    <div className="page-body">
                                        <div className="text-center cookie-wrap packshots">
                                            {/* <div className="floating-cookie1 bobber"></div> */}
                                            <img src={products} alt="OREO Space Dunk Cookie package (303g)" className="img-fluid" />
                                            <div className="floating-cookie2 bobber slow"></div>
                                            <div className="floating-cookie3 bobber reverse"></div>
                                        </div>
                                        <p id="pack-desc">{translate('products.body1', {br: <br />, b: chunks => <b>{chunks}</b>})}</p>
                                        <div className="cookie-grid">
                                            <img src={cookie1} alt="Oreo cookie with Astronaut" className="" />
                                            <img src={cookie2} alt="Oreo cookie with Star" className="" />
                                            <img src={cookie3} alt="Oreo cookie with Shooting Star" className="" />
                                            <img src={cookie4} alt="Oreo cookie with Telescope" className="" />
                                            <img src={cookie5} alt="Oreo cookie with Rocket Ship" className="" />
                                        </div>
                                        <div className="text-center">
                                            <a href={langPreferance === 'fr-CA' ? 'https://www.amazon.ca/-/fr/Biscuits-sandwich-chocolat-%C3%A9dition-limit%C3%A9e/dp/B0CRH7N3TS/ref=sr_1_1?__mk_fr_CA=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=TGXSBFP7XZ76&keywords=oreo%2Bspace%2Bdunk&qid=1707235655&sprefix=oreo%2Bspace%2Bdunk%2Caps%2C122&sr=8-1&th=1&language=en_CA' : 'https://www.amazon.ca/-/fr/Biscuits-sandwich-chocolat-%C3%A9dition-limit%C3%A9e/dp/B0CRH7N3TS/ref=sr_1_1?__mk_fr_CA=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=TGXSBFP7XZ76&keywords=oreo%2Bspace%2Bdunk&qid=1707235655&sprefix=oreo%2Bspace%2Bdunk%2Caps%2C122&sr=8-1&th=1&language=en_CA' } 
                                                target="_blank" rel="noopener noreferrer" className="enter-btn">
                                                {translate('products.link1')}
                                            </a> 
                                        </div>
                                        <p>{translate('products.body2', {br: <br />, b: chunks => <b>{chunks}</b>})}
                                        {translate('products.link2', {br: <br />, a: chunks => <NavLink to="/rules">{chunks}</NavLink>})}</p>

                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Products;