import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'Lift Off with Oreo',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        // 'menu.item.winner' : 'PAST WINNERS',
        'menu.item.1': 'ENTER CONTEST',
        'menu.item.2': 'PRIZE DETAILS',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RULES',
        'menu.item.5': 'OREO SPACE DUNK',

        'contact-email': 'customercare@oreoliftoff.com',

        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy',
        'footer.link3' : 'Quebec Privacy Officer',
        'footer.link4' : 'Terms of Use',
        'footer.link5' : 'Accessibility Policy',
        'footer.sponsor': 'Sponsor: Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9{br}{br}©Mondelez International group.{br}{br}All trademarks are property of their respective owners. Zero–G is not responsible for nor is a Sponsor of this promotion.',

        'legal' : '*NO PURCHASE NECESSARY. Open to residents of Canada (age of majority). Closes May 31/24 (11:59:59 p.m. ET). Enter online and full rules at: www.OREOLiftOff.ca . One (1) Grand Prize consisting of a Zero Gravity flight experience, (ARV CDN $30,000); and 30 Instant Win OREO branded space themed prizes, (ARV CDN $250) available to be won. Odds depend on number of eligible entries. Skill-testing question required.',
        'legal.link' : 'www.oreoliftoff.ca',

        //homapge
        'homepage.gameon' : '',
        'homepage.unlock': '',
        'homepage.prize1' : 'You Could',
        'homepage.prize' : 'A Zero-Gravity Experience',
        // 'homepage.pluscash': 'With Instant Win*',
        // 'homepage.pluscash2': 'Snack Caddies',
        // 'homepage.pluscash3': '(ARV $68)',
        // 'homepage.prepluscash': '$50,000 IN GEAR WITH INSTANT WIN* SNACK CADDIES (ARV $68)',
        // 'homepage.preprize': 'SNACK SEAT DRIVERS CONTEST COMING SOON',
        // 'homepage.cta' : 'scroll to learn how To Enter',
        // 'homepage.howtoenter' : 'HOW IT WORKS',
        // 'homepage.step1head' : 'STEP 1',
        // 'homepage.step1' : 'Enter any three (3) participating UPCs',
        // 'homepage.step2head' : 'STEP 2',
        // 'homepage.step2' : 'Look for product UPC found on your participating Mondelez product package.',
        // 'homepage.step3head' : 'STEP 3',
        // 'homepage.step3' : 'Look for the 11- digit product UPC numbers** found on your participating Mondelez product package.  <small>**Omit any numbers to left of the code.</small>',
        // 'homepage.offerhead': 'SUMMER OFFERS S’MORE TO EXPLORE!',
        // 'homepage.prod1': 'PB&J RITZ S’MORES',
        // 'homepage.prod1url': 'https://www.snackworks.ca/en/recipe/pbj-ritz-smores-206344.aspx',
        // 'homepage.prod2': 'CARAMEL PECAN CHIPS AHOY! S’MORES',
        // 'homepage.prod2url': 'https://www.snackworks.ca/en/recipe/caramel-pecan-chips-ahoy-smores-206343.aspx',
        // 'homepage.prod3': 'CADBURY S’MORES BROWNIES',
        // 'homepage.prod3url': 'https://www.snackworks.ca/en/recipe/cadbury-smores-crispy-treats-133481.aspx',
        // 'homepage.offercta': 'go to recipe',

        // 'contestClosedHead1' : 'Snack Seat Driver',
        'contestClosedHead' : 'CONTEST CLOSED!',
        'contestClosedText' : 'Thanks for visiting!{br}{br}Our contest is now closed but if you’re a Grand Prize Winner, you’ll receive an email after June 5, 2024. Good luck!',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',
        
        'enterCta' : 'ENTER NOW',
        'enterNow' : 'ENTER NOW',

         //coming soon and home page
        'comingsoon' : 'COMING SOON',
        'comingsoon.body' : 'Be sure to return <b>February 15th to enter!</b>',

        //enter contest page
        // 'step1': 'Step 2',
        // 'enterPin': 'ENTER 3 UPCs',
        // 'enterKeyDescription': 'Enter the 11-digit product UPC numbers** found on your ',
        // 'enterKeyLink': 'participating Mondelez product package.',
        // 'enterKeySmall': '**Omit numbers to left of the code.',
        // 'popupBodytext' : 'Look for the UPC code on the back of your purchase of a ',
        // 'popupBodylink' : 'participating product.',
        // 'pin': 'UPC Code',
        // 'nextStep': 'NEXT',

        // 'step2': 'Step 1',
        'enterInfo': 'UNLOCK MISSION DETAILS',
        'enterInfoDescription': 'Register for a chance to WIN* this life-changing journey with <b>Zero-G</b> plus instant prizes! Enter daily!',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'emailAddress': 'Email Address',
        'phone': 'Phone',
        'infoForm.age': 'I confirm that I am of the age of majority in the province/territory where I reside†',
        'infoForm.rules' : 'I have read and confirm compliance with the ',
            'infoForm.rules.link1' : 'Contest rules and regulations',
            'infoForm.rules2' : ' and the ',
            'infoForm.rules.link2' : 'Mondelēz Privacy Policy†',
            'infoForm.rules3' : ' ',
        'infoForm.optin' : 'I confirm my consent to Mondelez Canada Inc. storing, sharing and using my personal information submitted for the purpose of administering the Contest (including notifying me if I am selected as an eligible winner of a prize) in accordance with the Contest Rules and Regulations and the Mondelez Privacy Policy†',
        'requiredFields' : '†Required',
        'submit': 'Submit',

        'dunk.header' : 'DUNK FOR A CHANCE TO WIN*',
        'dunk.body' : 'Scroll to select your <b>OREO</b>. Press and drag to dunk and see if you’ve unlocked an instant prize.',

        'tmi.heading' : 'GO YOU!',
        'tmi.body' : 'Seems you’ve already earned your contest entry and game play for today. Come back daily through May 31, 2024 for more chances to WIN*{br}{br}*See <a>contest rules</a> for more details .',

        //winning page
        'congratulation' : 'ALMOST THERE!',
        // 'winningPage.heading' : 'YOU’RE A POTENTIAL WINNER!',
        // 'winningPage.body' : 'Before being declared an official winner, you must correctly answer, unaided, the mathematical skill-testing question below.',
        // 'winningPage.question' : '(5X5) – 13, then add 3 =',
        // 'winningPage.answer' : "Your Answer",
        'winningPage.cta' : 'SUBMIT',
        'winningPage.field1': 'Address',
        'winningPage.field2': 'Apartment/Suite',
        'winningPage.field3': 'City',
        'winningPage.field4': 'Province/Territory',
        'winningPage.field5': 'Postal Code',
        // 'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'CONGRATS!',
        'winningPage.heading2' : 'YOU’RE AN INSTANT WINNER!',
        'winningPage.body1' : 'You’ve been selected to WIN*‡ a Custom <b>OREO</b> Space Backpack!{br}{br}<small>‡Subject to verification</small>',
        'winningPage.body2' : 'To help our team get your prize to you, please enter the details below:',
        // 'winningPage.legal' : 'Please allow 4 weeks for shipping. customercare@OREOLIFTOFF.ca',

        // 'winningPage.heading3' : 'SORRY, INCORRECT ANSWER.',
        // 'winningPage.body3' : 'Please Try Again with another UPC code',

        'winningPage.heading4' : 'HOW DO I CLAIM MY PRIZE?',
        'winningPage.body4': 'Look for an e-mail from {emaillink} providing prize details within 72 hours.',
        'winningPage.trybutton': 'TASTE THE SPACE',

        'address1': 'Address',
        'address2': 'Apartment/Suite',
        'city': 'City',
        'prov': 'Province/Territory',
        'postal': 'Postal Code',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Download',


        //losing page
        'tryAgain' : 'AW SHUCKS!',
        'tryAgain.body' : 'You didn’t win an instant prize this time, but you still earned a contest entry toward the Zero-G experience Grand Prize!{br}{br}Come back daily for more chances to WIN*.',
        // 'tryAgain.btn' : 'TRY AGAIN', 
        'tryAgain.heading2' : 'SHARE THE FUN',
        'tryAgain.body2' : '',
        'tryAgain.body3' : 'Want to share to Instagram? Click the download button below and receive an image you can upload to your Instagram account.',

        //Prize Details
        'prizeDetails' : 'PRIZE DETAILS',

        'prizeDetails-heading1' : 'THE GRAND PRIZE',
        'prizeDetails-prod1' : " You could take flight in a Zero-G jet for a ride that lets you float, flip and soar! Feel weightless like an astronaut in outer space!{br}{br}Start the adventure at NASA’s Kennedy Space Center where you’ll receive flight training, an <b>OREO</b> flight suit and more!{br}{br}See <a>contest rules</a> for more details.",
        'prizeDetails-heading2' : 'Instant Prizes',
        'prizeDetails-body2' : "Setting out for new horizons means you’ll need the latest in gear! This custom <b>OREO</b> Space Themed Backpack is perfect for all your adventures.",

        // Participating Products
        'products' : 'TASTE THE SPACE!',
        'products.body1' : 'Wondering what cosmic creme tastes like? Like the delicious taste of marshmallows.{br}{br}Try our <b>OREO</b> Space Dunk Limited-Edition today!{br}{br}Cookies are packed with marshmallow flavoured creme and popping ‘stardust’ candy that come in 5 galactic cookie designs.',
        'products.link1' : 'BUY NOW',
        'products.body2' : 'While this <b>OREO</b> can be found on earth, entering for a chance to WIN* a Zero Gravity Experience will have you feeling out of this world!{br}{br}',
        'products.link2' : '*See <a>contest rules</a> for more details.',
        

        //faq page
        'faq-heading-text1': 'While the ',
            'faq-heading-link': 'Official Rules',
        'faq-heading-text2': ' outline the complete details of this promotion, your questions may already be answered below.',

        'q1': 'Q. What is the date period for this promotion?',
        'a1': 'A. Contest starts February 15th (12:00:00 a.m. ET) and ends May 31, 2024 (11:59:59 p.m. ET).',
        
        'q2': 'Q. What are the prizes for this promotion?',
        'a2': 'A. There will be one (1) grand prize available to be won, consisting of an OREO Zero Gravity Experience for 2 to the NASA Kennedy Space Center, FLA; 5 night’s stay in Orlando for 2, including airfare, hotel, and spending money. The Grand Prize trip must be taken between September 1, 2024 and October 31, 2024. (ARV $30,000 CAD).{br}{br}Plus, there will be thirty (30) instant win custom OREO Space Backpacks available to be won, (ARV $250 CAD each).',
        
        'q3': 'Q. How many times can I enter?',
        'a3': 'A. There is a limit of one (1) Entry per person, per day.',
        
        'q4': 'Q. How many prizes could I WIN*?',
        'a4': 'A. There is a limit of one (1) Grand Prize per person. Plus, a limit of 1 INSTANT WIN prize per person during the promotion.',

        'q5': 'Q. I have a valid UPC, but I am getting an error.',
        'a5': 'A. Please enter the code(s) without any dashes or spaces and refer to our ',
        'a5a': 'participating products',
        'a5b': ' line up to make sure your purchase is eligible. If it is still not working, we are here to help!\nPlease contact us at  ',

        // 'q6': 'Q. I have a valid PIN code, but I’m getting an error.',
        // 'a6': 'A. Please enter the 12-digit alpha PIN code without any dashes or spaces. If it’s still not working, we’re here to help! Please contact us at ',
        
        'q6': 'Q. I received a message that I have already entered today, but I have not. What should I do?',
        'a6': 'A. Make sure you are visiting www.OREOLIFTOFF.ca directly rather than a bookmarked page in your browser. Also, please try to clear cookies and cache on your internet browser. ',

        'q7': 'Q. I am having technical issues. What should I do?',
        'a7': 'A. Check to be sure that you have an up-to-date version of Chrome, Internet Explorer, Firefox, or Safari. Check your browser preferences section to be sure JavaScript cookies are enabled. Clearing your cache and cookies also helps in this instance.',

        'q8': 'Q. How will I know if I am a potential an INSTANT WIN Prize Winner?',
        'a8': 'A. You will be advised on the screen if you have been declared a potential Instant Win prize winner. Each potential winner will have to correctly answer a skill testing question on the screen to be declared an official winner. ',
        
        'q9': 'Q. I have recently updated my email address. Can I change my registration information for this promotion?',
        'a9': 'A. We apologize for any inconvenience, but due to the number of entries we receive, we are unable to change registration information.{br}{br}If you are entered into the Grand Prize Draw during the promotional period and your contact information does change, contact us at; ',
        'a9-b': 'customercare@OREOLIFTOFF.ca',
        'a9-c': ' and provide your updated information.',
        'a9-2': 'Please note: If your contact information changes during the promotional period, we cannot guarantee that we will be able to accommodate your request.',
        'a9-3': 'Still have a question? Please contact us at ',
        'a9-3b': 'customercare@OREOLIFTOFF.ca',
        'a9-3c': 'Please note: a response may take up to three (3) business days.',
        
        // 'q10': 'Q. I am having trouble getting my PC Game Pass to work? What should I do?',
        // 'a10': 'For details on how to redeem a Code, visit: ',
        // 'a10-link': 'https://support.xbox.com/en-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        // 'q11': 'Q. I have recently updated my email address. Can I change my registration information for this promotion?',
        // 'a11': 'A. We apologize for any inconvenience, but due to the number of entries we receive, we are unable to change registration information. If you are entered into the Grand Prize Draw during the promotional period and your contact information does change, contact ',
        // 'a11-2' : ' and provide your updated information.  {br}{br} Please note: If your contact information changes during the promotional period, we cannot guarantee that we will be able to accommodate your request. {br}{br} Still have a question? Please contact us at ',
        // 'a11-3' : ' Please note: a response may take up to three (3) business days.',

        //Rules page
        'rules-page-heading': '“SUMMER FUN” PROMOTION OFFICIAL RULES',
    }
}