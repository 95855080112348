import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import closeBtn from '../images/close black.png';
import { translate } from '../i18n/translate';

import milkglass from '../images/glass@2x.png';
import milkfill from '../images/glass-fill.png';
import milksplash from '../images/glass-splash.png';

import cookie1 from '../images/cookie1.png';
import cookie2 from '../images/cookie2.png';
import cookie3 from '../images/cookie3.png';
import cookie4 from '../images/cookie4.png';
import cookie5 from '../images/cookie5.png';
import cookie1b from '../images/cookie1-angle.png';
import cookie2b from '../images/cookie2-angle.png';
import cookie3b from '../images/cookie3-angle.png';
import cookie4b from '../images/cookie4-angle.png';
import cookie5b from '../images/cookie5-angle.png';

// todo. consider adding a timeout to trigger a dunk?

const cookielist = [cookie1,cookie2,cookie3,cookie4,cookie5];
const cookielistb = [cookie1b,cookie2b,cookie3b,cookie4b,cookie5b];

const xspacing = 200;
const xedge = xspacing * 2;
const ylimit = 180;

class Dunk extends Component {

    state = {
        dragging:false,
        dunked:false,
        tx:0,
        ty:0,
        dx:0,
        dy:0,
        thecookie:2,
    }
    rid = null;

    componentDidMount(){
        this.rid = requestAnimationFrame(this.updateFrame);

        gsap.from('h1, p, .cookie-carousel, .chevrons, .glass', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        });
    }
    componentWillUnmount(){
        cancelAnimationFrame(this.rid);
    }

    updateFrame = () => {
        // console.log('raf');
        this.rid = requestAnimationFrame(this.updateFrame);
        // console.log(this.rid);
        if (!this.state.dragging && (Math.abs(this.state.dx)>1 || Math.abs(this.state.dy)>1)){
            this.setState({dx:this.state.dx/2,dy:this.state.dy/2});
        }
    }

    onTouchStart = (e)=>{
        if (this.state.dunked) return;
        if (this.state.dragging) return;
        
        // cancelAnimationFrame(this.rid);
        const tx = e.clientX || e.touches[0].clientX;
        const ty = e.clientY || e.touches[0].clientY;
        this.setState({tx:tx,ty:ty,dragging:true});
    }
    onTouchUpdate = (e)=>{
        if (this.state.dunked) return;
        if (!this.state.dragging) return;
        
        const dx = (e.clientX || e.touches[0].clientX)- this.state.tx;
        const dy = (e.clientY || e.touches[0].clientY) - this.state.ty;
        if ( Math.abs(dx) > dy ){
            this.setState({dx:dx/2,dy:this.state.dy/2});
        } else {
            if (dy>ylimit) {
                this.onTouchEnd(e);
                return;
            }
            this.setState({dy:dy,dx:this.state.dx/2});
        }
        
    }
    onTouchEnd = (e)=>{
        if (this.state.dunked) return;

        let cookiedelta = 0, nx = 0;
        if (this.state.dy>100) {
            // trigger splash
            this.setState({dunked:true});
            setTimeout( ()=>{ this.props.onDunked(this.state.thecookie) }, 2000 );
            return;
        }
        if (this.state.dx>100 && this.state.thecookie>0) {
            cookiedelta = -1;
            nx = -xspacing;
        } else if (this.state.dx<-100 && this.state.thecookie<4){
            cookiedelta = 1;
            nx = xspacing;
        } else {

        }
        let thenewcookie = this.state.thecookie + cookiedelta;

        //this.setState({dx:0,dy:0,tx:0,ty:0,dragging:false,thecookie:thenewcookie})
        this.setState({dx:this.state.dx+nx,dragging:false,thecookie:thenewcookie});
        // this.rid = requestAnimationFrame(this.updateFrame);
    }
    onMouseLeave = (e)=>{
        if (this.state.dunked) return;

        if (this.state.dragging) {
            this.onTouchEnd(e);
        }
    }
    triggerDunk = ()=>{
        // this.setState({triggered:true});
        const thecookie = document.querySelector('.cookie-carousel img:nth-child('+(this.state.thecookie+1)+')');
        gsap.to(thecookie, .5, {y:ylimit, onComplete:()=>{ 
            this.setState({dunked:true});
            setTimeout( ()=>{ this.props.onDunked(this.state.thecookie) }, 2000 );
        }});
    }

    onKeyPress = (e)=>{
        console.log(e.which);
        if (e.which===39){ // >
            if (this.state.thecookie<4) {
                this.setState({thecookie:this.state.thecookie+1,dx:this.state.dx+xspacing});
                // console.log(document.querySelector('.cookie-carousel img:nth-child('+(this.state.thecookie)+')'))
                document.querySelector('.cookie-carousel img:nth-child('+(this.state.thecookie+2)+')').focus();
            }
        } else if (e.which===37){ // <
            if (this.state.thecookie>0) {       
                this.setState({thecookie:this.state.thecookie-1,dx:this.state.dx-xspacing});
                // console.log(document.querySelector('.cookie-carousel img:nth-child('+(this.state.thecookie)+')'))
                document.querySelector('.cookie-carousel img:nth-child('+(this.state.thecookie)+')').focus();
            }
        } else if (e.which===40 || e.which===13 || e.which===32) { // down, enter, space
            this.triggerDunk();
        }
    }

    render() {
        /* const cookieArr = [ cookielist[this.state.thecookie] ];
        for (let i=1;i<=3;i++){
            let pi = this.state.thecookie-i;
            if (pi<0) pi += 5;
            let ni = (this.state.thecookie+i)%5;
            cookieArr.unshift( cookielist[ pi ] );
            cookieArr.push( cookielist[ ni ] );
        } */
        
        return (
            <Fragment>
                <div id="main" className="main-wrapper no-short-rules">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-center">
                                    
                                    <h1>{translate('dunk.header')}</h1>
                                    <p>{translate('dunk.body',{b:chunks=><b>{chunks}</b>})}</p>
                                </div>
                                <div className="col-md-10 offset-md-1 text-center">

                                    <div className="dunk-frame">
                                        <div className={`glass ${this.state.dunked&&'dunked'}`}>
                                            <img src={milkglass} alt="glass of milk" />
                                            <img src={milkfill} alt="filled glass" id="fill" />
                                            <img src={milksplash} alt="splashed milk" id="splash" />
                                        </div>
                                        <div className="cookie-carousel" 
                                            onKeyUp={this.onKeyPress}
                                            onTouchStart={this.onTouchStart} 
                                            onTouchMove={this.onTouchUpdate} 
                                            onTouchEnd={this.onTouchEnd} 
                                            onMouseDown={this.onTouchStart} 
                                            onMouseMove={this.onTouchUpdate} 
                                            onMouseUp={this.onTouchEnd}
                                            onMouseLeave={this.onMouseLeave}>
                                            {cookielist.map( (e,i)=>{
                                                const di = Math.abs(this.state.thecookie - i);
                                                const cx = this.state.dx + ( (i-this.state.thecookie) * xspacing ); // -600 to 600
                                                const cs = (1000 - Math.abs(cx)) / 1000;// 1 - (di*.15);
                                                const co = Math.abs(cx)<xedge ? 1 : 1-( (Math.abs(cx)-xedge)/100 ); // should fade to 0 from 400.
                                                let cy = 0;

                                                // deal with y dragging.
                                                if (di===0 && this.state.dy>0){
                                                    cy = this.state.dy;
                                                }

                                                // deal with dunked state.
                                                let csrc = e;
                                                if (di===0 && this.state.dunked){
                                                    csrc = cookielistb[this.state.thecookie];
                                                }

                                                return <img src={csrc} key={i} alt="a cookie" 
                                                    draggable={false} className={`s${di}`} 
                                                    tabIndex={di===0?0:-1} style={{
                                                        transform: `translate(${cx}px, ${cy}px) scale(${cs})`,
                                                        zIndex: 5-di,
                                                        opacity: co
                                                }} />;
                                            })}
                                        </div>
                                        <div className="chevrons">
                                            <a href="#" onClick={this.triggerDunk}>
                                                {/* {translate('homepage.cta')} */}
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Dunk
