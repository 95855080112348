import React, { Component } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/src/ScrollTrigger';
import '../../styles/_global.scss';

gsap.registerPlugin(ScrollTrigger);

class Canvas extends Component{

    componentDidMount() {
        // var pstate = this.particles.state;
        // console.log(this);
        // const canvas = this.canvas;
        // const ctx = canvas.getContext("2d");
        // const floor = this._floorimage;
        // const fade = this._fadeimage;
        // const bg = this.bg;

        /*
        floor.onload = () => {
            // console.log(bg);
            bg.style.width = document.body.clientWidth;
            bg.style.height = document.body.clientHeight;
            //ctx.drawImage(floor, 0, canvas.height-floor.height);
            //ctx.drawImage(fade, 0, 0);
            // const pattern = ctx.createPattern(img, 'repeat');
            // ctx.fillStyle = pattern;
            // ctx.fillRect(0, 0, canvas.width, canvas.height);
            //ctx.font = "40px Courier"
            //ctx.fillText(this.props.text, 210, 75)
        } */

        gsap.to('.flare', {
            scrollTrigger: {
                trigger: '#root',
                start: 'top top',
                end: "+=500",
                scrub: true
            },
            y: 200
        });

        gsap.to('.floater1', {
            scrollTrigger: {
                trigger: '#root',
                start: 'top top',
                end: "+=800",
                scrub: true
            },
            top: "+=16em"
        });

        gsap.to('.floater2', {
            scrollTrigger: {
                trigger: '#root',
                start: 'top top',
                end: "+=800",
                scrub: true
            },
            top: "+=6em"
        });
    }

    render(){
        return(
            <div id="bg">
              <div className="flare"></div>  
              <div className="floater1 bobber"></div>
              <div className="floater2 bobber slow"></div>
            </div>
        )
    }
}

export default Canvas;