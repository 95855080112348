import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import { TextField } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';
import STQForm from '../forms/STQForm';
import AddressForm from '../forms/AddressForm';
import axios from 'axios';

//translate
import { translate, translatedlink } from '../../i18n/translate';

//images
import prize from '../../images/oreobackpack3.png';
import cookie1b from '../../images/cookie1-angle.png';
import cookie2b from '../../images/cookie2-angle.png';
import cookie3b from '../../images/cookie3-angle.png';
import cookie4b from '../../images/cookie4-angle.png';
import cookie5b from '../../images/cookie5-angle.png';
import milkring from '../../images/milk-ring.png';
import packshot from '../../images/spacedunk-packshot.png';

//css
import '../../styles/pages/_you-won.scss';


const cookielistb = [cookie1b,cookie2b,cookie3b,cookie4b,cookie5b];

class YouWon extends Component{

    state = {
        confirmed: 1
    }

    correctAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - correct');
        // ReactGA.pageview(window.location.pathname + '?status=stq-correct');
        this.setState({confirmed:1});
        window.scrollTo(0, 0);
        // send call to backend
        // axios.post('https://xbox-mondelez-contest.herokuapp.com/confirmation', 
        axios.post(process.env.REACT_APP_BACKEND_URL+'/confirmation', 
        { id:timestamp, correctanswer:true },
        { headers: { 'Content-Type': 'application/json' } });
    }
    incorrectAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - incorrect');
        // ReactGA.pageview(window.location.pathname + '?status=stq-incorrect');
        this.setState({confirmed:-1});
        window.scrollTo(0, 0);
        // send call to backend
        // axios.post('https://xbox-mondelez-contest.herokuapp.com/confirmation', 
        axios.post(process.env.REACT_APP_BACKEND_URL+'/confirmation', 
        { id:timestamp, correctanswer:false },
        { headers: { 'Content-Type': 'application/json' } });
    }
    tryAgain = (e) => {
        e.preventDefault();
        window.location.reload()
    }
    submitAddress = vals => {
        
        const { timestamp } = this.props;
        const { error, complete, valid, ...data } = vals;
        console.log('submit',{ timestamp, ...data });

        axios.post(process.env.REACT_APP_BACKEND_URL+'/updatewinner', 
        { id:timestamp, ...data },
        { headers: { 'Content-Type': 'application/json' } }); // todo. catch errors?

        // ReactGA.pageview(window.location.pathname + '?status=address-submitted');
        this.setState({confirmed:2});
        window.scrollTo(0, 0);
    }

    componentDidMount(){
        console.log('you won - cdm');
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        gsap.fromTo('#winning-oreo', {
            opacity: 0,
            y: 150,
            scale: .5
        }, {
            opacity: 1,
            y: 0,
            scale: 1.2,
            ease: Power3.easeOut,
            duration: .8
        });
        gsap.from('h1, #you-win-p1, #yeti-bag, #you-win-p2, form', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2, 
            delay: 1
        });
    }

    // handleChange = (e) => {
    //     this.setState({ val: e.target.value});
    // }

    render(){
        
        // const currentPage = window.location.href;
        const { confirmed } = this.state;

        return(
            <Fragment>

                <div id="main" className="main-wrapper bg-right-dt no-short-rules">
                    <section>
                        <div className="container win">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    {confirmed === 2 &&
                                    // you win! finished
                                        <div className="you-win">
                                            <h1>{translate('winningPage.heading4', {br: <br />})}</h1>
                                            <p>{translate('winningPage.body4',{emaillink:translatedlink('contact-email')})}</p>
                                            <img src={packshot} alt="Oreo Space Dunk package" className="packshot" />
                                            <NavLink className="enter-btn" to="/products">{translate('winningPage.trybutton',{b:c=><b>{c}</b>})}</NavLink>
                                        </div>
                                    }
                                    {confirmed === 1 &&
                                    // you win! enter your address.
                                        <div className="you-win">
                                            <h1>{translate('winningPage.heading1', {br: <br />})}</h1>
                                            <img id="winning-oreo" src={milkring} alt="Winning Oreo" style={{
                                                backgroundImage: `url(${cookielistb[this.props.cookie]})`,
                                                backgroundSize: 'cover'
                                            }} />
                                            <p id="you-win-p1">
                                                {translate('winningPage.body1', {br: <br />,b:c=><b>{c}</b>, small:c=><small>{c}</small>})}
                                            </p>
                                            <img id="yeti-bag" src={prize} alt="Yeti Bag" className="" />
                                            <p id="you-win-p2" className="mb-4">
                                                {translate('winningPage.body2', {br: <br />})}
                                            </p>
                                            <AddressForm onSubmit={this.submitAddress} values={this.props.values} />
                                        </div>
                                    }
                                    {confirmed === 0 &&                                     
                                    // STQ form
                                        <div className="you-win stq-form-container">
                                            <h1>{translate('congratulation', {br: <br />})}</h1>
                                            <h3>{translate('winningPage.heading')}</h3>
                                            <p>
                                                {translate('winningPage.body', {br: <br />})}
                                            </p>
                                           
                                            <STQForm nextCorrect={this.correctAnswer} nextIncorrect={this.incorrectAnswer} />
                                            {/* <p className="legal">{translate('winningPage.legal', {br: <br />})}</p> */}
                                        </div>
                                    }
                                    {confirmed === -1 && 
                                    // incorrect STQ
                                        <div className="you-win lose">
                                        <h1>{translate('winningPage.heading3', {br: <br />})}</h1>
                                        <h3>
                                            {translate('winningPage.body3', {br: <br />})}
                                        </h3>

                                        <button className="enter-btn" onClick={this.tryAgain}>{translate('tryAgain.btn')}</button>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouWon;