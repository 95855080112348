import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import InjectIntl from 'react-intl-inject';
// import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';
// import {InlineShareButtons} from 'sharethis-reactjs';


// import packshot from '../../../src/images/packages.png';
// import packshotfr from '../../../src/images/packages.png';

//images
import twitterIcon from '../../images/twitter.png';
import facebookIcon from '../../images/facebook.png';
import downloadBtn from '../../images/download.png';



//social share and :og tags
// import LosingKeySocialShare from '../LosingKeySocialShare';

//css
import '../../styles/pages/_you-lose.scss';
import { translate } from '../../i18n/translate';

class YouLose extends Component{
    
    state = {
        buttonHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //component did mount
    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        // language cookie setting for IG image
        // const langSelected = Cookie.get('lang-selected');
        // if(!langSelected) {
        //     Cookie.set('lang-preferance', 'en-US')
        // }

        // const enterNowButton = document.querySelector('.green-btn')
        // enterNowButton.addEventListener('click', (e) =>{
        //     e.preventDefault();
        //     window.location.reload()
        // })

        // const menuEnterContest = document.querySelector('#menu-enterContest')
        // menuEnterContest.addEventListener('click', (e) =>{
        //     e.preventDefault();
        //     window.location.reload()
        // })

        // const igDownloadBtn = document.querySelector('#ig-download');
        // igDownloadBtn.addEventListener('click', (e)=>{
        //     ReactGA.event({
        //         category: 'Download',
        //         action: 'Instagram'
        //       });
        // })

        gsap.from('.you-win > *', .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .2
        });
    }

    //facebook share new window
    fbShare = () => {
        // ReactGA.event({
        //     category: 'ShareThis',
        //     action: 'facebook'
        //   });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? process.env.REACT_APP_FR_URL : process.env.REACT_APP_EN_URL; 
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${siteDomain}`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    //twitter share new window // not used.
    twShare = () => {
        // ReactGA.event({
        //     category: 'ShareThis',
        //     action: 'twitter'
        //   });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? process.env.REACT_APP_FR_URL : process.env.REACT_APP_EN_URL; 
        const shareMessage = langPreferance === 'fr-CA' ? 'Courez la chance de GAGNER* une expérience zéro gravité avec OREO!' : 'You Could WIN* a Zero Gravity Experience with OREO!*';
        const shareLink = `https://twitter.com/intent/tweet?url=${siteDomain}&text=${encodeURIComponent(shareMessage)}`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    render(){

        // const remainingKeys = 5; 
        // const currentPage = window.location.href;
        // const siteDomain = 'snackonwithxbox.ca'; 
        const langPreferance = this.props.lang;
        const shareImg = langPreferance === 'fr-CA' ? '/liftoff-social-1200x1200-FR.jpg' : '/liftoff-social-1200x1200.jpg'; 

        return(
            <Fragment>
                <div id="main" className="main-wrapper you-lose bg-both-dt no-short-rules">
                    {/* <LosingKeySocialShare remainingKeys={remainingKeys} /> */}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="you-win">
                                        <h1>{translate('tryAgain')}</h1>
                                        <p>{translate('tryAgain.body', {br: <br />})}</p>
                                        <h2>{translate('tryAgain.heading2')}</h2>
                                        {/* <p>{translate('tryAgain.body2')}</p> */}
                                        <div className="social-container">
                                            <button onClick={this.fbShare} style={{background: 'transparent', border: 'none'}}><img src={facebookIcon} alt="Caramilk faceook"/></button>
                                            <button onClick={this.twShare} style={{background: 'transparent', border: 'none'}}><img src={twitterIcon} alt="caramilk twitter"/></button>
                                        </div>
                                        <p>{translate('tryAgain.body3')}</p>
                                        <a href={ shareImg } target="_blank" rel="noopener noreferrer" download id="ig-download">
                                            <InjectIntl>
                                                {({ intl }) => (
                                                    <img className="download-btn" src={downloadBtn} alt={intl.formatMessage({ id: 'download' })} />
                                                )}
                                            </InjectIntl>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouLose;