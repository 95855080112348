import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';

//language file
import { translate, translatedlink } from '../../i18n/translate';

import { getContestState } from '../../util';

//css for faq page
import '../../styles/pages/_faq.scss';

class Faq extends Component{

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper faq">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="page-heading">
                                        <h1>FAQ</h1>
                                    </div>
                                    <div className="page-body faq">
                                        <p>
                                            {translate('faq-heading-text1')}  
                                            <NavLink exact to="/rules">{translate('faq-heading-link')}</NavLink>
                                            {translate('faq-heading-text2')}
                                        </p>
                                        <div className="qa">
                                            <h3 className="q">{translate('q1')}</h3>
                                            <p className="a">{translate('a1')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q2')}</h3>
                                            <p className="a">
                                                {translate('a2', {br : <br />})}   
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q3')}</h3>
                                            <p className="a">{translate('a3')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q4')}</h3>
                                            <p className="a">{translate('a4')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q6')}</h3>
                                            <p className="a">{translate('a6')}}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q7')}</h3>
                                            <p className="a">{translate('a7')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q9')}</h3>
                                            <p className="a">
                                                {translate('a9', {br : <br />})}
                                                {translatedlink('contact-email')}
                                                {translate('a9-c')}<br /><br />
                                                {translate('a9-2')}<br /><br />
                                                {translate('a9-3')}
                                                {translatedlink('contact-email')}<br />
                                                {translate('a9-3c')} 
                                                
                                            </p>
                                        </div>
                                        
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Faq;